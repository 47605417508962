<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Header: Personal Info -->
        <div class="d-flex my-2">
          <feather-icon
            icon="BookIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            TIPPING
          </h4>
        </div>

        <validation-observer ref="simpleRules">
          <!-- Form: Personal Info Form -->
          <b-form class="mt-1">
            <b-row>
              <b-col cols="12">
                <div class="d-flex my-2">
                  <h4 class="mb-0">
                    Tipping Details
                  </h4>
                </div>
                <b-card no-body>
                  <b-card-body>
                    <!-- COMP NAME -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>COMP NAME (logged in)</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="comp name"
                          rules="required"
                        >
                          <b-form-input
                            v-model="compName"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <!-- COMP NAME -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>COMP NAME (not logged in)</span>
                      </b-col>
                      <b-col cols="9">
                        <b-form-input
                          v-model="guestName"
                          type="text"
                        />
                      </b-col>
                    </b-row>
                    <!-- OPEN DATE/TIME -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>SHOWDOWN OPEN DATE/TIME</span>
                      </b-col>
                      <b-col cols="9">
                        <flat-pickr
                          id="openDate"
                          v-model="openDate"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            dateFormat: 'Y-m-d H:i:s',
                            enableSeconds: true,
                            disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                          }"
                        />
                      </b-col>
                    </b-row>
                    <!-- COMPETITION DATE/TIME -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>FIRST LEG START DATE/TIME</span>
                      </b-col>
                      <b-col cols="9">
                        <flat-pickr
                          id="competitionDate"
                          v-model="competitionDate"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            dateFormat: 'Y-m-d H:i:s',
                            enableSeconds: true,
                            disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                          }"
                        />
                      </b-col>
                    </b-row>

                    <!-- END DATE/TIME -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>END DATE/TIME</span>
                      </b-col>
                      <b-col cols="9">
                        <flat-pickr
                          id="endDate"
                          v-model="endDate"
                          class="form-control"
                          :config="{
                            enableTime: true,
                            dateFormat: 'Y-m-d H:i:s',
                            enableSeconds: true,
                            disable: [{ from: '1970-01-01', to: formatDate(disableDate) }],
                          }"
                        />
                      </b-col>
                    </b-row>
                    <!--PARTICIPANTS DESCRIPTIONS -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>PARTICIPANTS DESCRIPTIONS</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="participants descriptions"
                          rules="required|limitCharacters"
                        >
                          <!-- <vue-editor
                            v-model="participantsDes"
                            :editor-toolbar="customToolbar"
                          /> -->
                          <vue2-tinymce-editor v-model="participantsDes" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <!--MORE INFO -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>MORE INFO</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="more info"
                          rules="required"
                        >
                          <b-form-input
                            v-model="moreInfo"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <!--REMEMBER -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>REMEMBER</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="remember"
                          rules="required"
                        >
                          <b-form-input
                            v-model="remember"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <!--SCRATCHINGS -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>SCRATCHINGS</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="scratchings"
                          rules="required"
                        >
                          <b-form-input
                            v-model="scratchings"
                            type="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <!-- ENTRY FEE -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>ENTRY FEE</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="price to"
                          rules="required"
                        >
                          <b-form-input
                            v-model="priceTo"
                            min="0"
                            type="number"
                            :no-wheel="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <!-- RE BUY FEE -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>RE BUY FEE</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="late change price"
                          rules="required"
                        >
                          <b-form-input
                            v-model="latePrice"
                            min="0"
                            type="number"
                            :no-wheel="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <!-- LIMIT REAL BETSLIP -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>LIMIT BETSLIP</span>
                      </b-col>
                      <b-col cols="9">
                        <validation-provider
                          #default="{ errors }"
                          name="limit betslip"
                          rules="required"
                        >
                          <b-form-input
                            id="limit-value"
                            v-model="limitBetslip"
                            :formatter="formatNumberBetslip"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <!-- Manual Betslip entry -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>MANUAL BETSLIP ENTRY (optional)</span>
                      </b-col>
                      <b-col cols="9">
                        <b-form-input
                          id="limit-value"
                          v-model="manualBetSlipEntry"
                          :formatter="formatNumberBetslip"
                        />
                      </b-col>
                    </b-row>
                    <!-- Api Price -->
                    <b-row class="mb-1 d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <span>BONUS ADDITIONAL TICKET (optional)</span>
                      </b-col>
                      <b-col cols="9">
                        <b-form-input
                          v-model="apiPrice"
                          min="0"
                          type="number"
                          placeholder="Enter 100 for 0.5 tickets @ $50"
                          :no-wheel="true"
                        />
                      </b-col>
                    </b-row>

                    <!-- PRIZE TYPE -->
                    <b-row class=" d-flex align-items-start">
                      <b-col
                        cols="3"
                        class="font-weight-bold"
                      >
                        <h4>Transfer Prize By </h4>
                      </b-col>
                      <b-col cols="9">
                        <b-form-radio-group
                          id="transfer-prize"
                          v-model="selectedTransferPrize"
                          :options="optionsTransferPrizeBy"
                          name="transfer-prize"
                        />
                      </b-col>
                    </b-row>

                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col class="d-flex justify-content-end mr-4 mt-4">
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  :disabled="loadingCreate"
                  @click="handleCreateComp"
                >
                  <Spinner v-if="loadingCreate" />
                  <template v-else>
                    Create comp
                  </template>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  VBModal,
  BCardBody,
  BFormInput,
  BButton,
  BFormRadioGroup,
} from 'bootstrap-vue'
import useToastHandler from '@/services/toastHandler'
import { required, integer, limitCharacters } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  formatISO,
  formatDate,
  convertTZ,
  getObjectChange,
  formatDateUTC,
  formatDateSend,
  formatDateTimeLocal,
  formatCurrencyBuiltInFunc,
  formatDateTimeAustralia, toCardinal,
} from '@/helpers/utils'
// import { VueEditor } from 'vue2-editor'
import { mapActions, mapState } from 'vuex'
import Spinner from '@core/components/Spinner'
import { subDays, subSeconds } from 'date-fns'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vue2TinymceEditor } from 'vue2-tinymce-editor'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BCardBody,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    // VueEditor,
    Spinner,
    BFormRadioGroup,
    flatPickr,
    Vue2TinymceEditor,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      toCardinal,
      formatDateTimeAustralia,
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ color: [] }],
        ['hr'],
      ],
      compName: '',
      percentWinner: '',
      openDate: new Date(),
      competitionDate: new Date(),
      endDate: new Date(),
      morningLockout: new Date(),
      afternoonLockout: new Date(),
      // eslint-disable-next-line no-useless-escape
      participantsDes: '<div style=\"line-height: 1.3;\"><span style=\"color: #384250; line-height: 14px;\">⏰ Showdown Lockout: 6:30 PM NSW Time on Friday, 17th</span> January <span style=\"color: #384250;\">2025</span></div>\n<div style=\"line-height: 1.3;\">&nbsp;</div>\n<div style=\"line-height: 1.3;\"><span style=\"color: #384250; line-height: 14px;\">🔓 Rebuy Opens: 6:30 PM NSW Time on Friday, 17th January 2025</span></div>\n<div style=\"line-height: 1.3;\">&nbsp;</div>\n<div style=\"line-height: 1.3;\"><span style=\"color: #384250; line-height: 14px;\">⌛ Rebuy Closes: 8:45 PM NSW Time on Friday, 17th January</span> 2025</div>\n<div style=\"line-height: 1;\">&nbsp;</div>\n<hr />\n<div style=\"line-height: 1;\">&nbsp;</div>\n<div style=\"line-height: 1.3;\"><span style=\"color: #384250;\">For this Magic Millions Night, we have selected 12 legs across the Magic Millions Gold Coast track plus some races from Pakenham &amp; Canterbury, featuring thrilling action under the lights!</span></div>',
      moreInfo:
        'https://upcprize-prod.s3.ap-southeast-2.amazonaws.com/Legal/Showdown+Rules+Published+Version+5+September+2024.pdf',
      remember: 'We pay best tote odds based on official dividends. Dead heat rules apply.',
      scratchings:
        'You will be automatically allocated the lowest numbered runner. Eg runner 1 is the lowest numbered non scratched runner.',

      priceTo: 50,
      latePrice: 15,
      disableDate: subDays(new Date(), 1),

      webImage: null,
      fileImage: null,
      mobileImage: null,
      fileMobileImage: null,

      selectedTransferPrize: 'Cash',
      optionsTransferPrizeBy: [
        { text: 'Cash', value: 'Cash' },
        { text: 'Bonus-Cash', value: 'Bonus-Cash' },
      ],

      apiPrice: null,

      limitBetslip: null,
      manualBetSlipEntry: null,
      guestName: null,

    }
  },

  computed: {
    ...mapState('tipping/create', ['loadingCreate']),

  },

  methods: {
    ...mapActions({
      createCompAsync: 'tipping/create/createTippingComp',
    }),
    async handleCreateComp() {
      const valid = await this.$refs.simpleRules.validate()
      if (!valid) return

      const data = {
        name: this.compName,
        ...(this.guestName && { guestName: this.guestName }),
        moreInfo: this.moreInfo,
        openForEntry: this.formatDateSend(this.openDate),
        competitionDate: this.formatDateSend(this.competitionDate),
        endDate: this.formatDateSend(this.endDate),
        participantDescriptions: this.participantsDes,
        remember: this.remember,
        scratchings: this.scratchings,
        price: this.priceTo,
        latePrice: this.latePrice,
        morningLockout: this.formatDateSend(subSeconds(new Date(this.endDate), 40)),
        afternoonLockout: this.formatDateSend(subSeconds(new Date(this.endDate), 30)),
        useFlexiRollOverWallet: 0,
        apiPrice: Number(this.apiPrice),
        joinTimesLimit: Number(this.limitBetslip),
        ...(this.manualBetSlipEntry && { manualBetSlipEntry: Number(this.manualBetSlipEntry) }),
        prizeType: this.selectedTransferPrize,
        lockoutLeg: this.formatDateSend(this.morningLockout),
        isJackpotEnabled: false,
        status: 'draft',
      }

      const res = await this.createCompAsync(data)
      if (res) {
        this.showToastSuccess('Success', 'Create competition successfully!')
        this.$router.push({ name: 'tipping-update', params: { id: res.competitionId } })
      }
    },
    formatNumberBetslip(value) {
      let val = value.replace(/[^0-9.]/g, '')
      if (!Number.isInteger(Number(val))) {
        val = ''
      }
      const parts = val.split('.')
      parts[0] = parts[0].replace(/^0+/, '')
      val = parts.join('.')
      const decimalRegex = /^\d*\.?\d*$/
      if (!decimalRegex.test(val)) {
        val = ''
      }
      const numericVal = parseFloat(val)
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(numericVal)) {
        val = ''
      } else if (numericVal < 1) {
        val = '1'
      }
      return val
    },

    formatNumber(value) {
      const regexNumber = /^[0-9.]+$/

      if (regexNumber.test(value)) {
        if (value.split('').filter(s => s === '.').length > 1) return value.slice(0, value.length - 1)
        if (value.includes('.')) {
          const decimalPart = value.split('.')[1]
          const integerPart = value.split('.')[0]

          if (decimalPart.length > 2) {
            return `${integerPart}.${decimalPart.slice(0, 2)}`
          }
        }
        return value
      }
      value = value.replace(/[^0-9.]/, '')
      return value
    },
  },
  setup(_, { emit }) {
    const { showToastSuccess, showToastError } = useToastHandler()
    const { refFormObserver, getValidationState, resetForm } = formValidation(() => { })

    const onSubmit = () => {
      emit('next-step')
    }

    return {
      showToastSuccess,
      showToastError,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      limitCharacters,
      required,
      integer,
      convertTZ,
      formatISO,
      formatDate,
      getObjectChange,
      formatDateUTC,
      formatDateSend,
      formatDateTimeLocal,
      formatCurrencyBuiltInFunc,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.text-modal {
  color: black !important;
}

.banner-img {
  max-width: 100%;
}
</style>
